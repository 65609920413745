import type React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import type { JobTeaserListItemProps } from '@xing-com/crate-jobs-xinglets';

import { ANIMATION_DURATION } from './constants';
import { DynamicHeightContainer } from './dynamic-height-container';
import { MatchingReasonHighlightLoading } from './loading';
import { Markdown } from './markdown';
import { TipBox } from './tip-box';

type MatchingReasonsHighlightProps = Required<
  Pick<JobTeaserListItemProps, 'matchingReasons'>
>;
type Props = MatchingReasonsHighlightProps & {
  expand: boolean;
  onExpand: () => void;
};

const MatchingReasons: React.FC<Props> = ({
  matchingReasons,
  expand,
  onExpand,
}) => {
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    if (matchingReasons.reasons || matchingReasons.error) {
      setIsLoadingVisible(false);

      const timer = setTimeout(() => {
        setIsContentVisible(true);
      }, ANIMATION_DURATION);

      return () => {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, [matchingReasons]);

  if (matchingReasons.reasons || matchingReasons.error) {
    return (
      <FadeInContainer $isVisible={isContentVisible}>
        {matchingReasons.reasons ? (
          <TipBox isEnabled={matchingReasons.index === 1}>
            <Markdown expand={expand} onExpand={onExpand}>
              {matchingReasons.reasons}
            </Markdown>
          </TipBox>
        ) : (
          <FormattedMessage id="JOBS_MATCHING_REASONS_ERROR" />
        )}
      </FadeInContainer>
    );
  }

  return (
    <FadeContainer $isFading={!isLoadingVisible}>
      <MatchingReasonHighlightLoading />
    </FadeContainer>
  );
};

export const MatchingReasonsHighlight: React.FC<
  MatchingReasonsHighlightProps
> = ({ matchingReasons }) => {
  // We need to keep the state here as otherwise the dynamic container does not
  // re-evaluate its height when the markdown text is expanded
  const [expand, setExpand] = useState(false);

  const onExpand = (): void => {
    if (!expand) {
      setExpand(true);
    }
  };

  return (
    <DynamicHeightContainer>
      <MatchingReasons
        matchingReasons={matchingReasons}
        expand={expand}
        onExpand={onExpand}
      />
    </DynamicHeightContainer>
  );
};

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeContainer = styled.div<{ $isFading: boolean }>`
  opacity: ${({ $isFading }) => ($isFading ? 0 : 1)};
  animation: ${({ $isFading }) => ($isFading ? fadeOut : 'none')}
    ${ANIMATION_DURATION}ms ease-out forwards;
`;

const FadeInContainer = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : 'none')}
    ${ANIMATION_DURATION}ms ease-in;
`;

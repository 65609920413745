import type * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import * as Styled from './tip-box.styles';
import type {
  TipBoxContentProps,
  TipBoxProps,
  TipBoxRootProps,
  TipBoxTriggerProps,
} from './tip-box.types';

export const TipBoxRoot = ({ ...props }: TipBoxRootProps): JSX.Element => (
  <Styled.TipBoxRoot {...props} />
);

TipBoxRoot.displayName = 'TipBox.Root';

export const TipBoxTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  TipBoxTriggerProps
>((props, forwardedRef) => (
  <Styled.TipBoxTrigger {...props} ref={forwardedRef} />
));

TipBoxTrigger.displayName = 'TipBox.Trigger';

export const TipBoxContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  TipBoxContentProps
>(
  (
    {
      children,
      className,
      showArrow = true,
      side = 'bottom',
      variant,
      ...props
    },
    forwardedRef
  ) => (
    <Styled.TipBoxContent
      $side={side}
      $variant={variant}
      className={className}
      data-xds="TipBox"
      side={side}
      sideOffset={4}
      {...props}
      ref={forwardedRef}
    >
      {children}
      {showArrow && <Styled.TipBoxArrow $variant={variant} />}
    </Styled.TipBoxContent>
  )
);

TipBoxContent.displayName = 'TipBox.Content';

export const TipBoxClose = Styled.TipBoxClose;

export const TipBox = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  TipBoxProps
>(
  (
    {
      children,
      className,
      defaultOpen,
      onOpenChange,
      open,
      showArrow = true,
      showClosingIndicator = true,
      trigger,
      variant = 'default',
      ...props
    },
    forwardedRef
  ) => {
    const intl = useIntl();

    return (
      <TipBoxRoot
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        open={open}
      >
        <TipBoxTrigger>{trigger}</TipBoxTrigger>
        <TipBoxContent
          arrowPadding={16}
          className={className}
          ref={forwardedRef}
          showArrow={showArrow}
          variant={variant}
          {...props}
        >
          {children}
          {showClosingIndicator && (
            <Styled.TipBoxClose
              aria-label={intl.formatMessage({
                id: 'XDS_CLOSE_BUTTON_A11Y',
                defaultMessage: 'Close',
              })}
              data-qa="close"
              data-testid="close"
            >
              <IconCross width={18} height={18} />
            </Styled.TipBoxClose>
          )}
        </TipBoxContent>
      </TipBoxRoot>
    );
  }
);

TipBox.displayName = 'TipBox';

import { useIntl } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useLocalStorage } from '@xing-com/crate-hooks-use-web-storage';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { TipBoxPreset } from '@xing-com/tip-box';

type Props = {
  isEnabled?: boolean;
  children: React.ReactNode;
};

const LOCAL_STORAGE_KEY = 'mr-tip-box-date';

export const TWO_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000;

export const TipBox: React.FC<Props> = ({ isEnabled, children }) => {
  const { formatMessage } = useIntl();
  const isBottomSide = useMatchMedia(mediaTopBarVisible);
  const [lastShown, setLastShown] = useLocalStorage(LOCAL_STORAGE_KEY, 0);

  if (!isEnabled) {
    return children;
  }

  const onOpenAutoFocus = (e: Event): void => {
    e.preventDefault();
  };

  const onClick = (e: React.SyntheticEvent): void => {
    // This prevents clicking on the job item when clicking the tip box close button
    e.preventDefault();
    e.stopPropagation();
  };

  const onOpenChange = (open: boolean): void => {
    if (!open) {
      setLastShown(Date.now());
    }
  };

  const showTipBox = Date.now() - lastShown > TWO_DAYS_IN_MS;

  return (
    <TipBoxPreset
      side={isBottomSide ? 'bottom' : 'left'}
      align="start"
      variant="highlighted"
      markerText={formatMessage({ id: 'XDS_FLAG_NEW_NEUTRAL' })}
      headlineText={formatMessage({
        id: 'JOBS_MATCHING_REASONS_TIP_HEADLINE',
      })}
      bodyText={formatMessage({
        id: 'JOBS_MATCHING_REASONS_TIP_SUBTITLE',
      })}
      defaultOpen={showTipBox}
      onOpenAutoFocus={onOpenAutoFocus}
      onClick={onClick}
      onOpenChange={onOpenChange}
      trigger={children}
    />
  );
};

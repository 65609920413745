import type * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import { Button } from '@xing-com/button';
import { Marker } from '@xing-com/marker';
import { spaceL } from '@xing-com/tokens';
import { Headline, BodyCopy } from '@xing-com/typography';

import { TipBox } from '.';
import type { TipBoxPresetProps } from './tip-box.types';

export const TipBoxPreset = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  TipBoxPresetProps
>(
  (
    {
      bodyText,
      buttonLabel,
      buttonProps,
      headlineText,
      markerText,
      variant = 'default',
      ...props
    },
    forwardedRef
  ) => (
    <TipBox {...props} ref={forwardedRef} variant={variant}>
      {markerText && (
        <Marker variant="strong" style={{ marginRight: spaceL }}>
          {markerText}
        </Marker>
      )}
      {headlineText && (
        <Headline size="small" style={{ marginRight: spaceL }}>
          {headlineText}
        </Headline>
      )}
      {bodyText && <BodyCopy size="small">{bodyText}</BodyCopy>}
      {buttonLabel && (
        <Button
          size="small"
          variant={variant === 'default' ? 'primary' : 'onColor'}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
    </TipBox>
  )
);

TipBoxPreset.displayName = 'TipBoxPreset';

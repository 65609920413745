import { css, keyframes } from 'styled-components';

import {
  motionEasingEnter,
  motionEasingLeave,
  motionTimeS,
  spaceS,
} from '@xing-com/tokens';

// *** Keyframes ***
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideUpIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(${spaceS});
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDownIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-${spaceS});
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(${spaceS});
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideRightIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-${spaceS});
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideUpOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-${spaceS});
  }
`;

const slideDownOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(${spaceS});
  }
`;

const slideLeftOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-${spaceS});
  }
`;

const slideRightOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(${spaceS});
  }
`;

// *** Arrow Animation ***
export const arrowAnimation = css`
  &[data-state='open'] {
    animation: ${fadeIn} ${motionTimeS} ${motionEasingEnter} forwards;
  }
  &[data-state='closed'] {
    animation: ${fadeOut} ${motionTimeS} ${motionEasingLeave} forwards;
  }
`;

// *** Animation Styles Based on Position ***
export const getAnimationStyles = (
  $side: string | undefined
): ReturnType<typeof css> => {
  switch ($side) {
    case 'top':
      return css`
        &[data-state='open'] {
          animation: ${slideDownIn} ${motionTimeS} ${motionEasingEnter} forwards;
        }
        &[data-state='closed'] {
          animation: ${slideUpOut} ${motionTimeS} ${motionEasingLeave} forwards;
        }
      `;
    case 'bottom':
      return css`
        &[data-state='open'] {
          animation: ${slideUpIn} ${motionTimeS} ${motionEasingEnter} forwards;
        }
        &[data-state='closed'] {
          animation: ${slideDownOut} ${motionTimeS} ${motionEasingLeave}
            forwards;
        }
      `;
    case 'left':
      return css`
        &[data-state='open'] {
          animation: ${slideRightIn} ${motionTimeS} ${motionEasingEnter}
            forwards;
        }
        &[data-state='closed'] {
          animation: ${slideLeftOut} ${motionTimeS} ${motionEasingLeave}
            forwards;
        }
      `;
    case 'right':
      return css`
        &[data-state='open'] {
          animation: ${slideLeftIn} ${motionTimeS} ${motionEasingEnter} forwards;
        }
        &[data-state='closed'] {
          animation: ${slideRightOut} ${motionTimeS} ${motionEasingLeave}
            forwards;
        }
      `;
    default:
      return css`
        &[data-state='open'] {
          animation: ${fadeIn} ${motionTimeS} ${motionEasingEnter} forwards;
        }
        &[data-state='closed'] {
          animation: ${fadeOut} ${motionTimeS} ${motionEasingLeave} forwards;
        }
      `;
  }
};

import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';

import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  dropShadowM,
  scale050,
  scale100,
  scale130,
  scale420,
  spaceM,
  spaceS,
  spaceXS,
  spaceXXS,
  xdlColorCardInspire04,
  xdlColorSelected,
  xdlColorTextInvert,
  xdlColorTextOnLight,
} from '@xing-com/tokens';

import type { TipBoxSideProps, TipBoxVariant } from '.';
import { arrowAnimation, getAnimationStyles } from './tip-box.motion';

type TipBoxContentTProps = {
  $side?: TipBoxSideProps;
  $variant?: TipBoxVariant;
};

type TipBoxArrowTProps = {
  $variant?: TipBoxVariant;
};

// *** Variants ***
export const variants = {
  default: {
    arrow: css`
      fill: ${xdlColorSelected};
    `,
    content: css`
      background-color: ${xdlColorSelected};
      color: ${xdlColorTextInvert};
    `,
  },
  highlighted: {
    arrow: css`
      fill: ${xdlColorCardInspire04};
    `,
    content: css`
      background-color: ${xdlColorCardInspire04};
      color: ${xdlColorTextOnLight};
    `,
  },
};

// *** Components ***
export const TipBoxRoot = styled(PopoverPrimitive.Root)``;

export const TipBoxTrigger = styled(PopoverPrimitive.Trigger)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TipBoxContent = styled(
  PopoverPrimitive.Content
)<TipBoxContentTProps>`
  border-radius: ${cornerRadiusXL};
  box-shadow: ${dropShadowM};
  box-sizing: border-box;
  max-width: ${scale420};
  padding: ${spaceM};
  z-index: ${zIndexElevatedLayer4};

  /* Ensure the animation works properly */
  will-change: transform, opacity;
  transform-origin: var(--radix-popover-content-transform-origin);

  ${(props) => css`
    ${props.$variant &&
    css`
      ${variants[props.$variant].content};

      & :is(h1, h2, h3, h4, h5, h6, p) {
        ${variants[props.$variant].content};
      }

      & [data-xds='Marker'] {
        margin-block-end: ${spaceXS};
      }

      & [data-xds='Marker'] [data-xds='BodyCopy'] {
        margin-block-end: 0;
      }

      & [data-xds='Headline'] {
        margin-block-end: ${spaceXXS};
      }

      & [data-xds='BodyCopy'] {
        margin-block-end: ${spaceS};
      }
    `};

    /* Apply animation based on side */
    ${props.$side && getAnimationStyles(props.$side)};
  `};
`;

export const TipBoxArrow = styled(PopoverPrimitive.Arrow)<TipBoxArrowTProps>`
  width: ${scale100};
  height: ${scale050};

  /** Bugfix */
  visibility: visible;

  /* Arrow animation */
  ${arrowAnimation};

  ${(props) => css`
    ${props.$variant &&
    css`
      ${variants[props.$variant].arrow};
    `};
  `};
`;

export const TipBoxClose = styled(PopoverPrimitive.Close)`
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${scale130};
  inset-block-start: 0;
  inset-inline-end: 0;
  justify-content: center;
  position: absolute;
  width: ${scale130};

  &:focus-visible {
    outline: 2px solid ${xdlColorTextInvert};
    border-radius: 50%;
  }
`;
